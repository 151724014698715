import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';

import useWindowSize from '../../functions/useWindowSize'
import { lgWidth, mdWidth } from '../../functions/ArticleUtils'


const TopAvailableProperties = () => {
    const iframeOrigin = 'https://www.kontor.com'
    const iframeSource = `${iframeOrigin}/public/top-available-properties`
    const windowSize = useWindowSize()
    const displayWidth = windowSize.width > mdWidth ? '346px' : '320px';
    const columnCount = windowSize.width > lgWidth ? 3 : (windowSize.width > mdWidth ? 2 : 1)

    const { contentfulHomepageAvailablePropertiesList } = useStaticQuery(graphql`
    query {
        contentfulHomepageAvailablePropertiesList {
            properties {
              officeNames
            }
          }
      }
`)

    let links = contentfulHomepageAvailablePropertiesList.properties.map((item) => item.officeNames.split('/')[item.officeNames.split('/').length - 1]);

    return (
        <>
            <h2 data-aos="fade-up" data-aos-duration="1000" className="text-center mt-40 mb-16 px-8 text-4xl">
                Some of our Featured Properties
            </h2>
            <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
                className="w-full max-w-6xl mx-auto px-2 my-16 flex flex-wrap -m-4 justify-between"
            >

                {links.map((link) => (
                    <div
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className=" flex flex-col justify-between"
                        style={{
                            height: '500px',
                            flex: `0 0 calc(100% / ${columnCount} - 2rem)`
                        }}
                    >
                        <iframe
                            title="Top Featured Properties"
                            src={`${iframeSource}/${link}`}
                            className="flex h-full w-full group block"
                            allow="clipboard-read"
                            style={{
                                width: displayWidth,
                                border: 'none',
                                overflow: 'hidden',

                            }}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default TopAvailableProperties
